export const MAX_WIDTH = '500px';
export const BREAKPOINTS = {
    xs: 0,
    xm: 480,
    sm: 9999,
    md: 9999,
    lg: 9999,
    xl: 9999,
};
export default {};
