import { useQuery, useLazyQuery } from '@apollo/client';
import * as Schema from '@core_modules/seller/services/graphql/schema';

export const getSeller = (options = {}) =>
    useQuery(Schema.getSeller, {
        ...options,
        fetchPolicy: 'no-cache',
        context: {
            request: 'internal',
        },
    });

export const getEtalase = (options = {}) =>
    useQuery(Schema.getEtalase, {
        ...options,
        fetchPolicy: 'no-cache',
        context: {
            request: 'internal',
        },
    });

export const getEtalaseByPath = (options = {}) =>
    useQuery(Schema.getEtalaseByPath, {
        ...options,
        fetchPolicy: 'no-cache',
        context: {
            request: 'internal',
        },
    });
export const getEtalaseByPathLazy = (options = {}) =>
    useLazyQuery(Schema.getEtalaseByPath, {
        ...options,
        fetchPolicy: 'no-cache',
        context: {
            request: 'internal',
        },
    });

export const getProductBySellerId = (options = {}) => useQuery(Schema.getProductBySellerId, {
    ...options,
    fetchPolicy: 'no-cache',
});

export const getProductsLive = (options = {}) =>
    useQuery(Schema.getProductsLive, {
        ...options,
        fetchPolicy: 'no-cache',
    });

export default {
    getSeller,
    getEtalase,
    getProductBySellerId,
    getProductsLive,
    getEtalaseByPath,
    getEtalaseByPathLazy,
};
