import { getSeller } from '@core_modules/seller/services/graphql';
import Link from 'next/link';
import Thumbor from '@common_image/index';
import { sellerPathName } from '@config';

const SellerPage = (props) => {
    const { storeConfig } = props;
    const { data: dataSeller } = getSeller({
        variables: {},
    });

    return (
        <div className="row">
            {dataSeller?.getSeller?.map((seller, sellerIndex) => {
                const url = `/${sellerPathName}/${seller.seller_path}`;
                return (
                    <div key={`image-seller-${sellerIndex}`} className="col-xs-4 boxContainerImageSeller">
                        <Link href={seller.seller_path !== '' ? url : '#'}>
                            {seller.logo === '' || seller.logo === null ? (
                                <div className="sellerBroken">
                                    <span className="sellerName">{seller.name}</span>
                                </div>
                            ) : (
                                <Thumbor
                                    placeholderSqure
                                    positionAbsolute={false}
                                    paddingTop="0"
                                    className="imageSeller"
                                    src={seller.logo}
                                    width={150}
                                    height={150}
                                    widthMobile={150}
                                    heightMobile={150}
                                    storeConfig={storeConfig}
                                    style={{
                                        objectFit: 'contain',
                                    }}
                                />
                            )}
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default SellerPage;
